import { GetServerSideProps } from "next";
import Head from "next/head";

import { ProductsProvider } from "src/context";
import { fetchProducts } from "src/database/products";
import CurrentUserService from "src/services/user/current-user";
import { Product } from "types/schema";
import { NavigationProvider } from "foundation/NavigationProvider";

import { Home } from "../components/Home";

interface Props {
  products: Product[];
}

export default function Main({ products }: Props) {
  return (
    <NavigationProvider>
      <ProductsProvider products={products}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />;
        </Head>
        <Home />
      </ProductsProvider>
    </NavigationProvider>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const { req } = context;
    const user = await CurrentUserService.execute(req.cookies?.token);

    const procucts = await fetchProducts();

    return {
      props: {
        products: JSON.parse(JSON.stringify(procucts)),
        user,
      },
    };
  } catch (error) {
    console.log(error);
    // Notify bug tracker
    return {
      props: {
        courses: [],
      },
    };
  }
};
